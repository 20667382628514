import React, { useEffect, useRef, useState } from "react";
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Looses.scss";

import _ from "lodash";
import axios from "axios";
import { APIs } from "../../services/apiService";
import { ILoose } from "../../models/Loose";
import { addOutline, pencilOutline, trashOutline } from "ionicons/icons";
import { IPaginationMeta } from "../../models/PaginationMeta";
import { LooseItem } from "../../components/Loose/LooseItem/LooseItem";
import { LooseAddModal } from "../../components/Loose/LooseAddModal/LooseAddModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { UserTypes } from "../../models/User";
import { CustomList } from "../../components/List/List";
import Layout from "../../components/Layout/Layout";

export const Looses: React.FC = () => {
  const userType: any = useSelector<any>((state) => state.auth.user.type);
  const [loading, setLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedLooseToDelete, setSelectedLooseToDelete] =
    useState<ILoose | null>(null);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [search, setSearch] = useState("");
  const [looses, setLooses] = useState<ILoose[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });

  const looseAddModalRef: any = useRef();

  const getLooses = async (page: number) => {
    return await axios
      .get(APIs.looses.index, {
        params: {
          per_page: meta.per_page,
          page,
          search_key: search.length > 2 ? search : undefined,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in getting looses list");
        return {
          data: [],
          meta: {},
        };
      });
  };

  const addNewLooseToList = (loose: ILoose) => {
    // TODO : fix this code
    getInitialLooses();
    toast.success("Loose has been saved");
  };

  const updateLooseInList = (loose: ILoose) => {
    // TODO : fix this code
    getInitialLooses();
    toast.success("Loose has been saved");
  };

  const confirmDeleteLoose = (loose: ILoose) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    setShowDeleteAlert(true);
    setSelectedLooseToDelete(loose);
  };

  const deleteLoose = () => {
    setLoading(true);

    axios
      .delete(APIs.looses.delete(selectedLooseToDelete!.id))
      .then(() => {
        doRefresh();
        setSelectedLooseToDelete(null);
        setShowDeleteAlert(false);
        setLoading(false);
        toast.success("loose deleted successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in deleting loose");
        setLoading(false);
      });
  };

  const editLoose = (loose: ILoose) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    looseAddModalRef.current.showEditModal(loose);
  };

  const doFetch = async (event?: any) => {
    let data = await getLooses(meta.current_page + 1);

    setLooses(looses.concat(data.data!));
    setMeta(data.meta!);

    event.target.complete();

    if (looses.length >= meta.total) setIsInfinityLoadingDisabled(true);
  };

  const doRefresh = async (event?: any) => {
    setLoading(true);
    setLooses([]);
    setIsInfinityLoadingDisabled(false);

    let data = await getLooses(1);
    setLooses(data.data);
    setMeta(data.meta!);
    setLoading(false);

    if (event) event.target.complete();
  };

  const showAddModal = () => {
    looseAddModalRef.current.showModal();
  };

  const getInitialLooses = async () => {
    setLoading(true);

    let data = await getLooses(1);
    setLooses(data.data);
    setMeta(data.meta!);

    setLoading(false);
  };

  /**
   * life cycles
   */
  useEffect(() => {
    getInitialLooses();
  }, []);

  useEffect(() => {
    const searchLooses = async () => {
      setLoading(true);
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: 1,
      }));

      let data = await getLooses(meta.current_page);

      setLooses(data.data);
      setMeta(data.meta!);
      setLoading(false);
    };

    searchLooses();
  }, [search]);

  const columnList = [
    {
      coloumnName: "avatar",
      type: "avatar",
      label: "Avatar",
    },
    {
      coloumnName: "title",
      type: "string",
      label: "Name",
    },
    {
      coloumnName: "price",
      type: "string",
      label: "Price",
    },
    // {
    //   coloumnName: "updated_at",
    //   type: "date",
    //   label: "Last Update",
    // },
  ];

  const listActions = [
    {
      side: "start",
      options: [
        {
          title: "Edit",
          color: "secondary",
          actionType: "edit",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            editLoose(item);
          },
          icon: pencilOutline,
        },
      ],
    },
    {
      side: "end",
      options: [
        {
          title: "Delete",
          actionType: "delete",
          color: "danger",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            confirmDeleteLoose(item);
          },
          icon: trashOutline,
        },
      ],
    },
  ];

  return (
    <Layout
      pageTitle={"Looses"}
      backButtonRoute={"/dashboard"}
      onRefresh={doRefresh}
      search={search}
      onSearch={(e) => setSearch(e.detail.value!)}
      fabAccessRoles={[UserTypes.admin, UserTypes.manager]}
      fabIcon={addOutline}
      onFabClick={showAddModal}
    >
      <>
        <CustomList
          columnList={columnList}
          listActions={listActions}
          data={looses}
          loading={loading}
        />

        {/* Infinite Scroll */}
        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>

        {/* Add/Edit Modal */}
        <LooseAddModal
          ref={looseAddModalRef}
          onLooseInsert={addNewLooseToList}
          onLooseUpdate={updateLooseInList}
          userType={userType}
        />

        {/* Delete alert */}
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          header={"Delete"}
          message={"Do you want to delete the Loose?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Delete",
              handler() {
                deleteLoose();
              },
            },
          ]}
        />
      </>
    </Layout>
  );
};
