import React from "react";
import { IonAvatar } from "@ionic/react";

import "./Avatar.scss";

interface AvatarProps {
  img?: string;
  alt?: string;
  label?: string;
  color?: string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  img,
  alt = "img",
  label = "A",
  color,
  className,
}) => {
  const text = label.substr(0, 2).toUpperCase();

  return (
    <>
      <IonAvatar className={`avatar ${className}`}>
        {img ? (
          <img className="avatar-img" src={img} alt={alt} />
        ) : (
          <span className="no-avatar" style={{ color: color ?? "" }}>
            {text}
          </span>
        )}
      </IonAvatar>
    </>
  );
};
