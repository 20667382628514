import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemDivider,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import classNames from "classnames";
import { addOutline } from "ionicons/icons";
import _ from "lodash";
import { UserItem } from "../User/UserItem/UserItem";
import { WarehouseButton } from "../Warehouse/WarehouseButton/WarehouseButton";
import { UserTypes } from "../../models/User";

import "./Layout.scss";
import { useSelector } from "react-redux";

const Layout = ({
  containerClass = "orders-page",
  contentClass = "",
  pageTitle,
  backButtonRoute,
  backButtonTitle,
  headerButtons = [],
  search = "",
  onRefresh,
  doFetch,
  onFabClick,
  fabAccessRoles = [],
  fabIcon,
  fabRoute,
  onSearch = undefined,
  isInifinityLoadingDisabled,
  loading = false,
  children,
  showOnHeader,
}: {
  containerClass?: string;
  contentClass?: string;
  pageTitle?: string;
  search?: string;
  backButtonRoute?: string | null;
  backButtonTitle?: string;
  headerButtons?: Array<{
    title?: string;
    icon?: React.ReactNode;
    component?: React.ReactNode;
    action?: any;
    noAccess?: boolean;
  }>;
  onRefresh?: () => {};
  doFetch?: (event: any) => void;
  onFabClick?: () => {} | void;
  fabAccessRoles?: Array<(typeof UserTypes)[keyof typeof UserTypes]>;
  fabIcon?: string;
  fabRoute?: string;
  onSearch?: (e: any) => void;
  isInifinityLoadingDisabled?: boolean;
  loading?: boolean;
  children: JSX.Element;
  showOnHeader?: boolean;
}) => {
  const userType: any = useSelector<any>((state) => state.auth.user.type);

  return (
    <IonPage className={classNames("ion-page", containerClass)}>
      <IonHeader className={classNames("ion-no-border", "ion-header-desktop")}>
        <IonToolbar>
          <IonButtons slot="start">
            {!backButtonRoute ? (
              <IonMenuButton menu="main" autoHide={false} slot="start" />
            ) : (
              <IonBackButton defaultHref={backButtonRoute} />
            )}
          </IonButtons>
          <IonTitle className="ion-title">{pageTitle}</IonTitle>

          {showOnHeader && (
            <IonButtons slot="end" style={{ marginRight: 16 }}>
              {headerButtons.map(
                (btn) =>
                  !btn.noAccess &&
                  (btn.component ? (
                    btn.component
                  ) : (
                    <WarehouseButton
                      onClickHandler={btn.action}
                      text={btn.title ?? ""}
                      fill="outline"
                      size="small"
                      style={{ border: "none" }}
                    >
                      {btn.icon}
                    </WarehouseButton>
                  ))
              )}
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonHeader className="ion-header-mobile">
        <IonToolbar className="ion-toolbar">
          <IonButtons slot="start">
            {!backButtonRoute ? (
              <IonMenuButton menu="main" autoHide={false} />
            ) : (
              <IonBackButton defaultHref={backButtonRoute} text={pageTitle} />
            )}
          </IonButtons>
          <IonButtons slot="end">
            {headerButtons.map(
              (btn) =>
                !btn.noAccess &&
                (btn.component ? (
                  btn.component
                ) : (
                  <WarehouseButton
                    onClickHandler={btn.action}
                    text={btn.title ?? ""}
                    fill="outline"
                    size="small"
                    style={{ border: "none" }}
                  >
                    {btn.icon}
                  </WarehouseButton>
                ))
            )}
          </IonButtons>
        </IonToolbar>
        {onSearch && (
          <IonSearchbar
            value={search}
            onIonChange={onSearch}
            autocomplete="off"
            debounce={2500}
            class="search-component"
          />
        )}
      </IonHeader>

      <IonContent className="ion-content">
        <IonCard
          className={classNames("ion-card", !onSearch ? "" : "has-search")}
        >
          {(!!headerButtons?.length || onSearch) && (
            <>
              <IonCardHeader className="ion-card-header">
                {onSearch && (
                  <IonSearchbar
                    value={search}
                    onIonChange={onSearch}
                    autocomplete="off"
                    debounce={2500}
                    class="search-component"
                  />
                )}
                {!showOnHeader && (
                  <IonButtons
                    className={classNames("ion-buttons", "menu-container")}
                  >
                    {headerButtons.map(
                      (btn) =>
                        !btn.noAccess &&
                        (btn.component ? (
                          btn.component
                        ) : (
                          <WarehouseButton
                            onClickHandler={btn.action}
                            text={btn.title ?? ""}
                            fill="outline"
                            size="small"
                          >
                            {btn.icon}
                          </WarehouseButton>
                        ))
                    )}
                  </IonButtons>
                )}
              </IonCardHeader>
              <IonItemDivider className="ion-divider"></IonItemDivider>
            </>
          )}

          <IonCardContent
            className={classNames("ion-card-content", contentClass)}
          >
            {/* {onRefresh && (
              <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )} */}
            {loading
              ? _.times(10, (i) => <UserItem key={i} isLoader={true} />)
              : children}
          </IonCardContent>
        </IonCard>

        {doFetch && (
          <IonInfiniteScroll
            threshold="10px"
            onIonInfinite={doFetch}
            disabled={isInifinityLoadingDisabled}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            />
          </IonInfiniteScroll>
        )}
      </IonContent>

      {fabAccessRoles.includes(userType) && (fabRoute || onFabClick) && (
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            color="primary"
            onClick={onFabClick ? onFabClick : undefined}
            routerLink={fabRoute ?? undefined}
          >
            <IonIcon className="ion-fab-icon" icon={fabIcon ?? addOutline} />
          </IonFabButton>
        </IonFab>
      )}
    </IonPage>
  );
};

export default Layout;
