import React, { useEffect, useRef, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonButtons,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonBackButton,
  IonAvatar,
  IonLoading,
} from "@ionic/react";

import "./Profile.scss";
import axios from "axios";
import { APIs } from "../../services/apiService";
import { useDispatch } from "react-redux";
import { Actions } from "../../store/actionTypes";
import { toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";

export const Profile: React.FC = () => {
  const userImageRef = useRef<any>();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [avatar, setAvatar] = useState<any>(undefined);

  const getProfileData = () => {
    setLoading(true);

    axios
      .get(APIs.profile.get)
      .then((res) => {
        setId(res.data.data?.id);
        setName(res.data.data?.name);
        setEmail(res.data.data?.email);
        setAvatar(res.data.data?.avatar);
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error getting profile data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateProfile = () => {
    setLoading(true);

    const updateProfileRequestData = new FormData();

    updateProfileRequestData.append("id", id);
    updateProfileRequestData.append("name", name);
    updateProfileRequestData.append("email", email);

    if (avatar instanceof File)
      updateProfileRequestData.append("avatar", avatar);

    if (password !== "" && password) {
      updateProfileRequestData.append("password", password);
      updateProfileRequestData.append("password_confirm", passwordConfirm);
    }

    const updateProfileRequestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        APIs.profile.update,
        updateProfileRequestData,
        updateProfileRequestOptions
      )
      .then((res) => {
        // update redux state
        dispatch({
          type: Actions.AUTH.USER_LOGIN,
          payload: res.data.data,
        });

        toast.success("profile updated successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error updating profile");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setPicture = (event: any) => {
    const img = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      if (userImageRef.current)
        userImageRef.current!.src = e.target!.result!.toString();
    };

    reader.readAsDataURL(img);

    setAvatar(img);
  };

  // const clearPicture = () => {
  //   if (userImageRef.current)
  //     userImageRef.current!.src = '';
  //
  //   setAvatar(undefined);
  // }

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <Layout
      pageTitle={"Profile"}
      containerClass="profile"
      backButtonRoute={"/dashboard"}
    >
      <>
        <div className="profile-container">
          <IonAvatar
            className="user-avatar"
            style={{
              width: "100px",
              height: "100px ",
              backgroundColor: "#aaa",
            }}
          >
            {avatar ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#aaa",
                }}
                src={avatar}
                alt=""
                ref={userImageRef}
              />
            ) : (
              ""
            )}
          </IonAvatar>
          <label className="user-avatar-change-btn">
            Change Avatar
            <input type="file" onChange={setPicture} />
          </label>
          {/*<div className="user-avatar-delete-btn" onClick={clearPicture}>*/}
          {/*  Delete Avatar*/}
          {/*</div>*/}
          <form className="profile-form">
            <IonList className="profile-form-container">
              <IonItem>
                <IonLabel position="floating">Name</IonLabel>
                <IonInput
                  value={name}
                  onIonChange={(e) => setName(e.detail.value!)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  value={email}
                  onIonChange={(e) => setEmail(e.detail.value!)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value!)}
                  type="password"
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Password Confirm</IonLabel>
                <IonInput
                  value={passwordConfirm}
                  onIonChange={(e) => setPasswordConfirm(e.detail.value!)}
                  type="password"
                />
              </IonItem>
            </IonList>
            <IonButton
              className="profile-form-btn"
              color="danger"
              expand="block"
              onClick={updateProfile}
            >
              Update
            </IonButton>
          </form>
        </div>

        <IonLoading isOpen={loading} message={"Please wait..."} />
      </>
    </Layout>
  );
};
