export const dailyProductColumns = [
  {
    columnName: "product_size_id",
    label: "Code",
    type: "number",
    component: "input",
  },
  {
    columnName: "batch_number",
    label: "Batch",
    type: "number",
    component: "input",
  },
  {
    columnName: "quantity",
    label: "Quantity",
    type: "number",
    component: "input",
  },
  {
    columnName: "weight",
    label: "W (g)",
    type: "number",
    component: "input",
  },
  {
    columnName: "production_date",
    label: "Production",
    type: "date",
    component: "date",
  },
  {
    columnName: "expiration_date",
    label: "Expiry",
    type: "date",
    component: "date",
  },
];
