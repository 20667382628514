import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
} from "@ionic/react";
import React from "react";
import { statsChartOutline } from "ionicons/icons";
import "./Reports.scss";
import Layout from "../../components/Layout/Layout";

export const Reports: React.FC = () => {
  return (
    <Layout
      pageTitle={"Reports"}
      containerClass="orders-page"
      backButtonRoute={"/dashboard"}
    >
      <>
        <IonItem routerLink={`/reports/stores`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Stores</IonText>
        </IonItem>
        <IonItem routerLink={`/reports/returns`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Returns</IonText>
        </IonItem>
        {/* <IonItem routerLink={`/reports/productsPrices`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Products Prices</IonText>
        </IonItem> */}
        <IonItem routerLink={`/reports/productsPricesV2`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Products Prices</IonText>
        </IonItem>
        <IonItem routerLink={`/reports/productsStocks`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Products Stock</IonText>
        </IonItem>
      </>
    </Layout>
  );
};
