import React, { useEffect, useRef, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonRefresher,
  IonRefresherContent,
  IonAlert,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSearchbar,
} from "@ionic/react";
import { addOutline, pencilOutline, trashOutline } from "ionicons/icons";
import "./Users.scss";

import axios from "axios";
import _ from "lodash";
import { UserItem } from "../../components/User/UserItem/UserItem";
import { UserAddModal } from "../../components/User/UserAddModal/UserAddModal";
import { APIs } from "../../services/apiService";
import { IUser, UserTypes } from "../../models/User";
import { IPaginationMeta } from "../../models/PaginationMeta";
import { toast } from "react-toastify";
import { CustomList } from "../../components/List/List";
import Layout from "../../components/Layout/Layout";

export const Users: React.FC = () => {
  // TODO : change loading to content-loader instead of loading
  const [loading, setLoading] = useState(false);
  const [showDeleteUserAlert, setShowDeleteUserAlert] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] =
    useState<IUser | null>(null);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  // TODO : implement search scenario
  const [searchUser, setSearchUser] = useState("");
  const [users, setUsers] = useState<IUser[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });

  const userAddModalRef: any = useRef();

  const getUsers = async (page: number) => {
    return await axios
      .get(APIs.users.index, {
        params: {
          per_page: meta.per_page,
          page,
          search_key: searchUser.length > 2 ? searchUser : undefined,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in getting users list");
        return [];
      });
  };

  const addNewUserToList = (user: IUser) => {
    // TODO : fix this code
    getInitialUsers();
    toast.success("User has been saved");
  };

  const updateUserInList = (user: IUser) => {
    // TODO : fix this code
    getInitialUsers();
    toast.success("User has been saved");
  };

  const confirmDeleteUser = (user: IUser) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    setShowDeleteUserAlert(true);
    setSelectedUserToDelete(user);
  };

  const deleteUser = () => {
    setLoading(true);

    axios
      .delete(APIs.users.delete + selectedUserToDelete!.id)
      .then((res) => {
        doRefresh();
        setSelectedUserToDelete(null);
        setShowDeleteUserAlert(false);
        setLoading(false);
        toast.success("user deleted successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in deleting user");
      });
  };

  const editUser = (user: IUser) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    userAddModalRef.current.showEditModal(user);
  };

  const doFetch = async (event?: any) => {
    let data = await getUsers(meta.current_page + 1);

    setUsers(users.concat(data.data!));
    setMeta(data.meta!);

    event.target.complete();

    if (users.length >= meta.total) setIsInfinityLoadingDisabled(true);
  };

  const doRefresh = async (event?: any) => {
    setLoading(true);
    setUsers([]);
    setIsInfinityLoadingDisabled(false);

    let data = await getUsers(1);
    setUsers(data.data);
    setMeta(data.meta!);
    setLoading(false);

    if (event) event.target.complete();
  };

  const showAddModal = () => {
    userAddModalRef.current.showModal();
  };

  const getInitialUsers = async () => {
    setLoading(true);

    let data = await getUsers(1);
    setUsers(data.data);
    setMeta(data.meta!);

    setLoading(false);
  };

  /**
   * life cycles and effects
   */
  useEffect(() => {
    getInitialUsers();
  }, []);

  useEffect(() => {
    const searchUsers = async () => {
      setLoading(true);
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: 1,
      }));

      let data = await getUsers(meta.current_page);

      setUsers(data.data);
      setMeta(data.meta!);
      setLoading(false);
    };

    searchUsers();
  }, [searchUser]);

  const columnList = [
    {
      coloumnName: "avatar",
      type: "avatar",
      label: "Avatar",
    },
    {
      coloumnName: "name",
      type: "string",
      label: "Name",
    },
    {
      coloumnName: "type",
      type: "string",
      label: "Role",
    },
    // {
    //   coloumnName: "updated_at",
    //   type: "date",
    //   label: "Last Update",
    // },
  ];

  const listActions = [
    {
      side: "start",
      options: [
        {
          title: "Edit",
          color: "secondary",
          actionType: "edit",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            editUser(item);
          },
          icon: pencilOutline,
        },
      ],
    },
    {
      side: "end",
      options: [
        {
          title: "Delete",
          actionType: "delete",
          color: "danger",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            confirmDeleteUser(item);
          },
          icon: trashOutline,
        },
      ],
    },
  ];

  return (
    <Layout
      pageTitle={"Users"}
      backButtonRoute={"/dashboard"}
      search={searchUser}
      onSearch={(e) => setSearchUser(e.detail.value!)}
      onRefresh={doRefresh}
      fabAccessRoles={[
        UserTypes.admin,
        UserTypes.manager,
        UserTypes.saleRepresentative,
        UserTypes.picker,
        UserTypes.stockController,
      ]}
      fabIcon={addOutline}
      onFabClick={showAddModal}
    >
      <>
        <CustomList
          columnList={columnList}
          listActions={listActions}
          data={users}
          loading={loading}
        />
        {/* <IonList>
          {loading
            ? _.times(10, (i) => <UserItem key={i} isLoader={true} />)
            : users.map((user, index) => (
                <IonItemSliding key={index}>
                  <UserItem
                    name={user.name!}
                    type={user.type!}
                    avatar={user.avatar}
                  />
                  <IonItemOptions side="start">
                    <IonItemOption
                      color="primary"
                      onClick={() => editUser(user)}
                    >
                      Edit
                    </IonItemOption>
                  </IonItemOptions>
                  <IonItemOptions side="end">
                    <IonItemOption
                      color="danger"
                      onClick={() => confirmDeleteUser(user)}
                    >
                      Delete
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))}
        </IonList> */}

        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>

        <UserAddModal
          ref={userAddModalRef}
          onUserInsert={addNewUserToList}
          onUserUpdate={updateUserInList}
        />

        <IonAlert
          isOpen={showDeleteUserAlert}
          onDidDismiss={() => setShowDeleteUserAlert(false)}
          header={"Delete"}
          message={"Do you want to delete the user?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Delete",
              handler() {
                deleteUser();
              },
            },
          ]}
        />
      </>
    </Layout>
  );
};
