import React, { useEffect, useRef, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonAlert,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Tickets.scss";

import _ from "lodash";
import { addOutline } from "ionicons/icons";
import { IPaginationMeta } from "../../models/PaginationMeta";
import { ITicket } from "../../models/Ticket";
import axios from "axios";
import { APIs } from "../../services/apiService";
import { TicketItem } from "../../components/Ticket/TicketItem/TicketItem";
import { TicketAddModal } from "../../components/Ticket/TicketAddModal/TicketAddModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import { UserTypes } from "../../models/User";

export const Tickets: React.FC = () => {
  const currentUserUsername: any = useSelector<any>(
    (state) => state.auth?.user?.username
  );

  const [loading, setLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedTicketToDelete, setSelectedTicketToDelete] =
    useState<ITicket | null>(null);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [search, setSearch] = useState("");
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });

  const ticketAddModalRef: any = useRef();

  const getTickets = async (page: number) => {
    return await axios
      .get(APIs.tickets.index, {
        params: {
          per_page: meta.per_page,
          page,
          search_key: search.length > 2 ? search : undefined,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        toast.error("error in getting tickets list");
        return {
          data: [],
          meta: {},
        };
      });
  };

  const showAddModal = () => {
    ticketAddModalRef.current.showModal();
  };

  const editTicket = (ticket: ITicket) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    ticketAddModalRef.current.showEditModal(ticket);
  };

  const addNewTicketsToList = (tickets: ITicket[]) => {
    // TODO : fix this code
    getInitialTickets();
    toast.success("Ticket has been saved");
  };

  const updateTicketInList = (ticket: ITicket) => {
    // TODO : fix this code
    getInitialTickets();
    toast.success("Ticket has been saved");
  };

  const confirmDeleteTicket = (ticket: ITicket) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    setShowDeleteAlert(true);
    setSelectedTicketToDelete(ticket);
  };

  const deleteTicket = () => {
    setLoading(true);

    axios
      .delete(APIs.tickets.delete(selectedTicketToDelete!.id))
      .then(() => {
        doRefresh();
        setSelectedTicketToDelete(null);
        setShowDeleteAlert(false);
        setLoading(false);
        toast.success("ticket deleted successfully");
      })
      .catch(() => {
        toast.error("error in deleting ticket");
        setLoading(false);
      });
  };

  const doFetch = async (event?: any) => {
    let data = await getTickets(meta.current_page + 1);

    setTickets(tickets.concat(data.data!));
    setMeta(data.meta!);

    event.target.complete();

    if (tickets.length >= meta.total) setIsInfinityLoadingDisabled(true);
  };

  const doRefresh = async (event?: any) => {
    setLoading(true);
    setTickets([]);
    setIsInfinityLoadingDisabled(false);

    let data = await getTickets(1);
    setTickets(data.data);
    setMeta(data.meta!);
    setLoading(false);

    if (event) event.target.complete();
  };

  const getInitialTickets = async () => {
    setLoading(true);

    let data = await getTickets(1);
    setTickets(data.data);
    setMeta(data.meta!);

    setLoading(false);
  };

  /**
   * life cycles
   */
  useIonViewWillEnter(() => {
    getInitialTickets();
  }, []);

  useEffect(() => {
    const searchTickets = async () => {
      setLoading(true);
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: 1,
      }));

      let data = await getTickets(meta.current_page);

      setTickets(data.data);
      setMeta(data.meta!);
      setLoading(false);
    };

    searchTickets();
  }, [search]);

  return (
    <Layout
      pageTitle={"Tickets"}
      backButtonRoute={"/dashboard"}
      search={search}
      onSearch={(e) => setSearch(e.detail.value!)}
      onRefresh={doRefresh}
      fabAccessRoles={[
        UserTypes.admin,
        UserTypes.manager,
        UserTypes.saleRepresentative,
        UserTypes.picker,
        UserTypes.stockController,
      ]}
      fabIcon={addOutline}
      onFabClick={showAddModal}
    >
      <>
        <IonList>
          {loading
            ? _.times(10, (i) => <TicketItem key={i} isLoader={true} />)
            : tickets.map((ticket, index) => (
                <IonItemSliding key={index}>
                  <TicketItem
                    id={ticket.id}
                    title={ticket.title}
                    to={
                      ticket.to.username !== currentUserUsername
                        ? ticket.to
                        : ticket.from
                    }
                    seen={ticket.seen}
                  />
                  <IonItemOptions side="start">
                    <IonItemOption
                      color="primary"
                      onClick={() => editTicket(ticket)}
                    >
                      Edit
                    </IonItemOption>
                  </IonItemOptions>
                  <IonItemOptions side="end">
                    <IonItemOption
                      color="danger"
                      onClick={() => confirmDeleteTicket(ticket)}
                    >
                      Delete
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))}
        </IonList>

        {/* Infinite Scroll */}
        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>

        {/* Add/Edit Modal */}
        <TicketAddModal
          ref={ticketAddModalRef}
          onTicketInsert={addNewTicketsToList}
          onTicketUpdate={updateTicketInList}
        />

        {/* Delete alert */}
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          header={"Delete"}
          message={"Do you want to delete the ticket?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Delete",
              handler() {
                deleteTicket();
              },
            },
          ]}
        />
      </>
    </Layout>
  );
};
