import React, { useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonLoading,
  IonTextarea,
  useIonViewWillEnter,
  IonAlert,
  IonIcon,
} from "@ionic/react";
import {
  attachOutline,
  chevronBackOutline,
  chevronForwardOutline,
  storefrontOutline,
  telescopeOutline,
} from "ionicons/icons";
import "./NewOrder.scss";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { APIs } from "../../services/apiService";
import { IOrder, IOrderSize, ORDER_STATUS } from "../../models/Order";
import { IOrderProductItem, ProductItem } from "../../models/OrderProductItem";
import { OrderProductModal } from "../../components/Order/ProductModal/OrderProductModal";
import { useParams, useLocation } from "react-router";
import { OrderSummary } from "../../components/Order/OrderSummary/OrderSummary";
import { OrderForm } from "../../components/Order/OrderForm/OrderForm";
import { LoosePanel } from "../../components/Order/LoosePanel/LoosePanel";
import { ILoose } from "../../models/Loose";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { history } from "../../AppRouter";
import { ProductRow } from "../../components/Order/ProductRow/ProductRow";
import { useForceUpdate } from "../../hooks/ForceUpdate";
import { OrderStoreSelectModal } from "../../components/Order/StoreModal/OrderStoreSelectModal";
import { IStore } from "../../models/Store";
import { UserTypes } from "../../models/User";
import { AttachmentModal } from "../../components/AttachmentModal/AttachmentModal";
import { ReturnPanel } from "../../components/Order/ReturnPanel/ReturnPanel";
import { dataUrlToFile, resizeFile } from "../../utils";
import Layout from "../../components/Layout/Layout";

export const NewOrder: React.FC = () => {
  const bigBoy = [41, 521, 174, 191, 195, 371];
  const { id } = useParams();
  const forceUpdate = useForceUpdate();
  const userType: any = useSelector<any>((state) => state.auth?.user?.type);
  const [tempImage, setTempImage] = useState<string | undefined>();
  const [displayAttachment, setDisplayAttachment] = useState(false);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [saveOrderConfirmation, setSaveOrderConfirmation] = useState<{
    visible: boolean;
    type: "save" | "draft";
  }>({
    visible: false,
    type: "save",
  });
  const [categorizedProductList, setCategorizedProductList] = useState<
    Array<{
      column_number: number;
      categories: Array<IOrderProductItem>;
    }>
  >([]);
  const [productList, setProductList] = useState<Array<ProductItem>>([]);
  const [looses, setLooses] = useState<Array<ILoose>>([]);
  const [order, setOrder] = useState<IOrder>({
    id: "",
    store: {
      id: "",
      name: "",
      address: {},
      message: "",
    },
    sizes: [],
    halves: [],
    returns: [],
    looses: [],
    lastbalance: 0,
    message: "",
    created_at: "",
    received_payment: 0,
    start_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    attachment: undefined,
    abbreviation: "",
  });
  const [storeLastOrders, setStoreLastOrders] = useState<Array<IOrder>>([]);
  const [loadingStore, setLoadingStore] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({
    id: "",
    modalVisiblility: false,
  });
  const [slideChanging, setSlideChanging] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [
    dontShowStoreMessageAgainConfirm,
    setDontShowStoreMessageAgainConfirm,
  ] = useState(false);
  const [storeSelectModalVisible, setStoreSelectModalVisible] = useState(false);

  const toggleAttachmentModal = () => setDisplayAttachment(!displayAttachment);

  const setOrderStore = (store: IStore) => {
    setOrder((prevState) => ({
      ...prevState,
      store,
    }));
  };

  const setOrderAbbreviation = (e: any) => {
    setOrder((prevState) => ({
      ...prevState,
      abbreviation: e.target.value,
    }));
  };

  const setOrderRecievedPayment = (e: any) => {
    setOrder((prevState) => ({
      ...prevState,
      received_payment: e.target.value,
    }));
  };

  const setOrderMessage = (e: any) => {
    setOrder((prevState) => ({
      ...prevState,
      message: e.target.value,
    }));
  };

  const addItemToReturns = () => {
    setOrder((prevState) => {
      prevState.returns.push({
        code: "",
        count: "",
        type: "bag",
      });

      // TODO : choose better option instead of force updating compnent
      setTimeout(() => {
        forceUpdate();
      }, 10);

      return prevState;
    });
  };

  const deleteItemFromReturns = (index: number) => {
    setOrder((prevState) => {
      prevState.returns.splice(index, 1);

      setTimeout(() => {
        forceUpdate();
      }, 10);

      return prevState;
    });
  };

  const setOrderReturnCode = (e: any, index: number) => {
    setOrder((prevState) => {
      prevState.returns[index].code = e.target.value;

      forceUpdate();

      return prevState;
    });
  };

  const setOrderReturnCount = (e: any, index: number) => {
    setOrder((prevState) => {
      prevState.returns[index].count = e.target.value;

      forceUpdate();

      return prevState;
    });
  };

  const setOrderReturnType = (e: any, index: number) => {
    setOrder((prevState) => {
      prevState.returns[index].type = e.target.value;

      forceUpdate();

      return prevState;
    });
  };

  const changeOrderSize = (
    size: IOrderSize,
    actionType: "add" | "remove",
    vat: number
  ) => {
    setOrder((prevState) => {
      const newState = _.clone(prevState);

      const orderSizeIndex = _.findIndex(
        newState.sizes,
        (s) => s.id === size.id
      );

      if (actionType === "add") {
        if (_.findIndex(order.halves, (h) => h === size.id) === -1)
          if (orderSizeIndex > -1) {
            newState.sizes[orderSizeIndex].count++;
          } else {
            newState.sizes.push({
              id: size.id,
              count: 1,
              type: size.type,
              price: size.price,
              vat,
            });
          }
      } else {
        if (orderSizeIndex > -1) {
          // if (newState.sizes[orderSizeIndex].count === 1) {
          //   newState.sizes.splice(orderSizeIndex, 1);
          // } else {
          //   newState.sizes[orderSizeIndex].count--;
          // }
          newState.sizes.splice(orderSizeIndex, 1);
        }
      }

      return newState;
    });
  };

  const setOrderSize = (size: IOrderSize, count: number) => {
    setOrder((prevState) => {
      const newState = _.clone(prevState);

      const orderSizeIndex = _.findIndex(
        newState.sizes,
        (s) => s.id === size.id
      );

      if (count > 0) {
        if (orderSizeIndex > -1) {
          newState.sizes[orderSizeIndex].count = count;
        } else {
          newState.sizes.push({
            id: size.id,
            count: count,
            type: size.type,
            price: size.price,
          });
        }
      } else {
        if (orderSizeIndex > -1) {
          newState.sizes.splice(orderSizeIndex, 1);
        }
      }

      return newState;
    });
  };

  const addOrRemoveHalf = (
    normalSize: IOrderSize,
    availableOnStock: boolean,
    vat: number
  ) => {
    if (_.findIndex(order.sizes, (s) => s.id === +normalSize.id) === -1) {
      let halves;
      let halvesData;
      if (!availableOnStock) {
        if (
          _.xor(order.halves, [+normalSize.id])?.length > order.halves?.length
        ) {
          halves = order.halves;
          halvesData = order.halves_data;
        }
      } else {
        setOrder((prevState) => {
          const newState = _.clone(prevState);
          let chosenBefore = newState.halves.find(
            (id) => Number(normalSize.id) === id
          );
          halves = _.xor(order.halves, [+normalSize.id]);
          halvesData = newState?.halves_data ?? [];
          if (!chosenBefore)
            halvesData?.push({
              id: normalSize.id,
              type: normalSize.type,
              price: Number(normalSize.price) ?? 0,
              vat,
            });
          else
            halvesData = halvesData.filter((half) => half.id !== normalSize.id);
          return {
            ...prevState,
            halves,
            halves_data: halvesData,
          };
        });
      }
    }
  };

  const changeOrderLoose = (loose: ILoose, actionType: "add" | "remove") => {
    setOrder((prevState) => {
      const newState = _.clone(prevState);

      const orderLooseIndex = _.findIndex(
        newState.looses,
        (l) => l.id === loose.id
      );

      if (actionType === "add") {
        if (orderLooseIndex > -1) {
          newState.looses[orderLooseIndex].quantity++;
        } else {
          newState.looses.push({
            id: loose.id,
            quantity: 1,
            price: loose.price,
            vat: loose.vat,
          });
        }
      } else if (orderLooseIndex > -1) {
        newState.looses.splice(orderLooseIndex, 1);
      }

      return newState;
    });
  };

  const setOrderLoose = (loose: ILoose, quantity: number) => {
    setOrder((prevState) => {
      const newState = _.clone(prevState);

      const orderLooseIndex = _.findIndex(
        newState.looses,
        (l) => l.id === loose.id
      );

      if (quantity > 0) {
        if (orderLooseIndex > -1) {
          newState.looses[orderLooseIndex].quantity = quantity;
        } else {
          newState.looses.push({
            id: loose.id,
            quantity: quantity,
            price: loose.price,
          });
        }
      } else {
        if (orderLooseIndex > -1) {
          newState.sizes.splice(orderLooseIndex, 1);
        }
      }

      return newState;
    });
  };

  const getOrder = async (orderId: string | number) => {
    try {
      const requestData = {
        params: {
          from_order: 1,
        },
      };
      const fetchedOrder = await axios
        .get(APIs.order.show(orderId), requestData)
        .then((res) => res.data.data);

      fetchedOrder.sizes = _.map(fetchedOrder.sizes, (size) => ({
        ...size,
        count: size.pivot.count,
      }));

      fetchedOrder.looses = _.map(fetchedOrder.looses, (loose) => ({
        ...loose,
        quantity: loose.pivot.quantity,
      }));

      setIsDraft(fetchedOrder.order_status === ORDER_STATUS.draft);
      setOrder(fetchedOrder);
    } catch (e) {
      toast.error(e.customErrorMessage ?? "error recieving order data.");
    }
  };

  const uploadOrderImg = async (event: any) => {
    try {
      const img = event.target.files[0];
      if (img) {
        setLoading(true);
        const reader = new FileReader();
        reader.onload = (e) => {
          setLoading(false);
        };
        reader.readAsDataURL(img);
        const image = (await resizeFile(img)) as string;
        const imageFile = await dataUrlToFile(image, img.name);
        setTempImage(image);
        setOrder((prevState) => ({
          ...prevState,
          attachment: imageFile,
        }));
      }
    } catch {}
  };

  const showSaveConfirmationOrder = (type: "save" | "draft") => {
    setSaveOrderConfirmation({
      visible: true,
      type,
    });
  };

  const saveOrderOrSaveAsDraft = () => {
    if (saveOrderConfirmation.type === "draft") {
      saveOrder(false);
    } else {
      saveOrder();
    }
  };

  const saveOrder = async (setStatus: boolean = true, autoSave?: boolean) => {
    if (
      order.sizes.length <= 0 &&
      order.looses.length === 0 &&
      order.halves.length === 0
    ) {
      toast.error("please select products.");
      return;
    }

    if (order.store?.id === "") {
      toast.error("please select the store");
      return;
    }

    setLoading(true);

    const orderRequestData = new FormData();

    // @ts-ignore
    orderRequestData.append("store_id", order.store?.id);
    orderRequestData.append(
      "received_payment",
      // @ts-ignore
      order.received_payment
    );
    // @ts-ignore
    orderRequestData.append("message", order.message ?? "");
    orderRequestData.append("abbreviation", order.abbreviation ?? "");
    order.sizes.forEach((size: any, index: any) => {
      orderRequestData.append(`sizes[${index}][id]`, size.id);
      orderRequestData.append(`sizes[${index}][count]`, size.count);
    });
    order.looses.forEach((loose: any, index: any) => {
      orderRequestData.append(`looses[${index}][id]`, loose.id);
      orderRequestData.append(`looses[${index}][quantity]`, loose.quantity);
    });
    order.halves.forEach((half: any) => {
      orderRequestData.append(`halves[]`, half);
    });
    order.returns.forEach((ret: any, index: any) => {
      orderRequestData.append(`returns[${index}][code]`, ret.code);
      orderRequestData.append(`returns[${index}][count]`, ret.count);
      orderRequestData.append(`returns[${index}][type]`, ret.type);
    });
    // @ts-ignore
    if (order.start_at) orderRequestData.append("start_at", order.start_at);

    if (order.attachment?.size > 0)
      orderRequestData.append("attachment", order.attachment);

    try {
      let orderResult: any;

      if (isEdit) {
        orderRequestData.append("_method", "PATCH");
        orderResult = await axios
          .post(APIs.order.update(order.id), orderRequestData)
          .then((res) => res.data);
      } else
        orderResult = await axios
          .post(APIs.order.store, orderRequestData)
          .then((res) => res.data);

      if (orderResult.status && !autoSave) {
        if (setStatus) {
          changeOrderStatus(orderResult.data.id, isEdit);
        } else {
          toast.success("Order updated successfully.");
          finishOrder();
        }

        // if (!isEdit) {
        // fetch(
        //   "//fcm.googleapis.com/v1/projects/react-learning-3c852/messages:send",
        //   {
        //     headers: {
        //       accept: "*/*",
        //       authorization:
        //         "Bearer AAAAbnoUiHo:APA91bGxOnKdwkGkFn0XF_wQf8L3eyfQ3IW2s3cbW-DTVJU2IANJzd1vHT4ZKuSI3VS_Xurm5lWbKFM_j3TBrjqB4ACO_5nYIUiZ8aOZiYJWLfWIn6UEBEjllHWHCuncPOvSYZesuCd3",
        //     },
        //     body: JSON.stringify({
        //       message: {
        //         token:
        //           "dqarY3e4zgrlMCaQeZJzVL:APA91bFpVIBwsFT0st4kE6WI3aHzv8KZrBILuQ2Dt5sS0asmaV76qi9kcHl1zgvtHvh4Q7hCKAJKnqoRGiYgngO338etK1PmsI8cFGNqKNwxHKaWbYCinGjlFt5eZdhPAaCZvJUPDpek",
        //         notification: {
        //           title: "new order",
        //           body: `${username} has saved an order`,
        //         },
        //         webpush: {
        //           headers: {
        //             Urgency: "high",
        //           },
        //           notification: {
        //             body: `${username} has saved an order`,
        //             requireInteraction: "true",
        //           },
        //         },
        //       },
        //     }),
        //     method: "POST",
        //   }
        // );
        // }
      } else if (orderResult.status && !autoSave) {
      }
    } catch (e) {
      setLoading(false);
      toast.error(e.customErrorMessage ?? "Order didn`t save.");
    }
  };

  const changeOrderStatus = (orderId: string, isEdit = false) => {
    setLoading(true);

    const setOrderStatusRequestData = {
      status: ORDER_STATUS.pending,
    };

    axios
      .put(APIs.order.setStatus(orderId), setOrderStatusRequestData)
      .then(() => {
        toast.success(
          isEdit ? "Order updated successfully." : "Order created successfully."
        );
        finishOrder();
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error saving data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const finishOrder = () => {
    history.location.state = { refresh: true };
    history.goBack();
  };

  const sliderNextPage = () => {
    if (currentSlide > storeLastOrders.length + 1) return;

    try {
      setSlideChanging(true);
      setCurrentSlide((prevState) => prevState + 1);

      setTimeout(() => {
        setSlideChanging(false);
      }, 500);
    } catch (e) {}
  };

  const sliderPrevPage = () => {
    if (currentSlide === 0) return;

    try {
      setSlideChanging(true);
      setCurrentSlide((prevState) => prevState - 1);

      setTimeout(() => {
        setSlideChanging(false);
      }, 500);
    } catch (e) {}
  };

  const showProductDetailsModal = (productId: any) => {
    setSelectedProduct({
      id: productId,
      modalVisiblility: true,
    });
  };

  /**
   * this method will recieve all products from backend and transform data to 3 column data
   */
  const gerProductList = async () => {
    setLoading(true);

    try {
      let data = await axios
        .get(APIs.order.productList)
        .then((res) => res.data.data);
      setProductList(data);

      data = _.chain(data)
        .groupBy("column_number")
        .map((val, key) => {
          const categories = val;
          categories.forEach((cat) => {
            cat.products.sort(
              (a: any, b: any) =>
                a.order - b.order ||
                a.sizes[0].code - b.sizes[0].code ||
                a.sizes[1].code - b.sizes[1].code ||
                a.sizes[2].code - b.sizes[2].code ||
                a.sizes[3].code - b.sizes[3].code
            );
          });
          return {
            column_number: key,
            categories,
          };
        })
        .value();

      setCategorizedProductList(data);
      setLoading(false);
    } catch (e) {
      toast.error(e.customErrorMessage ?? "ERROR getting products");
      setLoading(false);
    }
  };

  const getAllLooses = async () => {
    try {
      const data = await axios
        .get(APIs.order.looseList)
        .then((res) => Promise.resolve(res.data.data));
      setLooses(data);
    } catch (e) {
      toast.error("error getting loose data");
    }
  };

  const formatDateOfOrder = (date?: any) => {
    return date ? moment(date).format("ll") : moment().format("ll");
  };

  const showDontShowAgainStoreMessageConfirmation = () => {
    setDontShowStoreMessageAgainConfirm(true);
  };

  const acceptDontShowAgainStoreMessage = () => {
    axios.get(APIs.stores.dontShowMessageAgain(order.store!.id));
  };

  const selectBBProducts = () => {
    const products = productList
      .map((categoryProducts: any) => categoryProducts.products)
      .flat();
    bigBoy.forEach((code) => {
      products.forEach((product) => {
        const bbItem = product.sizes.find((size: any) => size.code === code);
        if (bbItem && bbItem.stock) {
          changeOrderSize(bbItem, "add", product.vat);
        }
      });
    });
  };

  const selectAllProducts = () => {
    const products = productList
      .map((categoryProducts: any) => categoryProducts.products)
      .flat();
    products.forEach((product) => {
      product.sizes.forEach((size: any) => {
        if (size.code && size.stock) changeOrderSize(size, "add", product.vat);
      });
    });
  };

  const autoSaveAsDraft = async () => {
    if (
      (order.sizes.length <= 0 &&
        order.looses.length <= 0 &&
        order.halves.length <= 0) ||
      order.store?.id === "" ||
      loading
    )
      return;

    if (isEdit && !isDraft) return;

    try {
      // add flo w for updating order
      const result = await saveOrder(false, true);
    } catch (e) {}
  };

  // TODO : autosave HAS BUG !
  // useInterval(autoSaveAsDraft, 80000);

  /**
   * effects and lifeCycles
   */
  useIonViewWillEnter(() => {
    const componentDidMountHandler = async () => {
      // get Initial value
      setLoading(true);

      await Promise.all([gerProductList(), getAllLooses()]).finally(() => {
        setLoading(false);
      });

      // set formType based on route (set to edit if id != null)
      if (id) {
        setIsEdit(true);
        getOrder(id);
      }
    };

    componentDidMountHandler();
  });

  /**
   * change store hook
   * first we will change order.store.message = '' and after then fetch new store
   */
  useEffect(() => {
    const getStoreLastOrders = async (id: any) => {
      const lastOrdersRequestParams = {
        per_page: 6,
        excepts: order.id ? [order.id] : undefined,
      };

      return await axios
        .get(APIs.stores.lastOrdersOfStore(id), {
          params: lastOrdersRequestParams,
        })
        .then((res) => {
          setStoreLastOrders(res.data.data);
        })
        .catch((err) => {
          toast.error(err.customErrorMessage ?? "ERROR Loading last Orders");
        });
    };

    const storeId = order.store?.id;
    if (storeId !== "") {
      setLoadingStore(true);

      // show store message
      if (order.store?.message && order.store?.message != "")
        toast.warn(order.store.message, {
          autoClose: false,
          onClick:
            order.store.message_status === 2
              ? () => {}
              : showDontShowAgainStoreMessageConfirmation,
          onClose:
            order.store.message_status === 2
              ? () => {}
              : showDontShowAgainStoreMessageConfirmation,
        });

      // get store last orders
      Promise.all([getStoreLastOrders(storeId)])
        .then(() => {
          setLoadingStore(false);
        })
        .finally(() => {
          setLoadingStore(false);
        });
    }
  }, [order.store]);

  useEffect(() => {
    if (isEdit || !id) setStoreSelectModalVisible(true);
    let addItemTimeout: NodeJS.Timeout;
    if (!isEdit && !id) {
      addItemTimeout = setTimeout(() => {
        if (
          order.sizes?.length <= 0 &&
          order.looses?.length <= 0 &&
          order.halves?.length <= 0
        ) {
          history.goBack();
        }
      }, 180000);
    }

    return () => {
      if (addItemTimeout) clearTimeout(addItemTimeout);
    };
  }, []);

  return (
    <Layout
      pageTitle={`New Order: ${order.store?.name}`}
      containerClass="new-order-page"
      backButtonRoute={"/orders"}
      headerButtons={[
        {
          title: "",
          icon: <IonIcon icon={storefrontOutline} />,
          action: () => setStoreSelectModalVisible(true),
          noAccess: order?.status === ORDER_STATUS.complete,
        },
        {
          title: "",
          icon: <IonIcon icon={attachOutline} />,
          action: () => toggleAttachmentModal(),
          noAccess: !order.attachment,
        },
      ]}
      showOnHeader={true}
    >
      <>
        <IonGrid className="py-0">
          <IonRow>
            <IonCol className="p-0 mt-1" size="12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-inline-flex align-items-center flex-2">
                  <IonLabel>
                    Store: {order.store?.name} (balance: {order.store?.balance})
                  </IonLabel>
                </div>
                <div className="flex-1">
                  {!loadingStore && (
                    <IonGrid className="mx-5">
                      <IonRow>
                        <IonCol
                          className="d-flex justify-content-center p-0"
                          size="12"
                        >
                          <IonButton
                            className="slider-btn d-flex justify-content-center align-items-center mx-1 mx-md-3"
                            onClick={selectAllProducts}
                          >
                            SA
                          </IonButton>
                          <IonButton
                            className="slider-btn d-flex justify-content-center align-items-center mx-1 mx-md-3"
                            onClick={selectBBProducts}
                          >
                            BB
                          </IonButton>
                          {currentSlide !== 1 && (
                            <IonButton
                              className="slider-btn d-flex justify-content-center align-items-center mx-1 mx-md-3"
                              onClick={sliderPrevPage}
                              disabled={currentSlide === 0}
                            >
                              <IonIcon
                                icon={chevronBackOutline}
                                ios={chevronBackOutline}
                              />
                            </IonButton>
                          )}
                          <IonButton
                            className="slider-btn d-flex justify-content-center align-items-center mx-1 mx-md-3"
                            onClick={sliderNextPage}
                            disabled={
                              currentSlide === storeLastOrders.length + 1
                            }
                          >
                            {currentSlide === 1 ? (
                              <IonIcon icon={telescopeOutline} />
                            ) : (
                              <IonIcon
                                icon={chevronForwardOutline}
                                ios={chevronForwardOutline}
                              />
                            )}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                </div>
                <div className="flex-2 ion-text-end">
                  Date:
                  {currentSlide < 2 ? (
                    <span>{formatDateOfOrder(order.created_at)}</span>
                  ) : (
                    <span>
                      {formatDateOfOrder(
                        storeLastOrders[currentSlide - 2]?.created_at
                      )}
                    </span>
                  )}
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        {!loadingStore && !slideChanging && (
          <>
            {
              /* main order Form */
              currentSlide === 1 && (
                <IonGrid className="py-0 w-100">
                  <div className="order-form-container w-100">
                    <IonRow id="order-form" className="order-form m-0">
                      {/* order product Form */}
                      {_.map(categorizedProductList, (col) => (
                        <IonCol
                          key={col.column_number}
                          className={`col-${col.column_number}`}
                          sizeXl="4"
                        >
                          {
                            // iterate for each category
                            _.map(col.categories, (category) => (
                              <table
                                className="order-form-table"
                                key={category.id}
                              >
                                <thead>
                                  <tr className="order-form-category">
                                    <th className="order-form-category-name">
                                      <h2>{category.title}</h2>
                                    </th>
                                    <th className="size">
                                      <h2>S</h2>
                                    </th>
                                    <th className="size">
                                      <h2>N</h2>
                                    </th>
                                    <th className="size">
                                      <h2>M</h2>
                                    </th>
                                    <th className="size">
                                      <h2>L</h2>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {_.map(category.products, (product) => (
                                    <ProductRow
                                      key={product.id}
                                      product={product}
                                      order={order}
                                      addOrRemoveHalf={addOrRemoveHalf}
                                      showProductDetailsModal={
                                        showProductDetailsModal
                                      }
                                      changeOrderSize={changeOrderSize}
                                      type="new"
                                    />
                                  ))}
                                </tbody>
                              </table>
                            ))
                          }

                          {col.column_number == 3 && (
                            <>
                              <LoosePanel
                                order={order}
                                looses={looses}
                                changeOrderLoose={changeOrderLoose}
                                setOrderLoose={setOrderLoose}
                              />
                              <ReturnPanel
                                order={order}
                                setOrderReturnCode={setOrderReturnCode}
                                setOrderReturnCount={setOrderReturnCount}
                                setOrderReturnType={setOrderReturnType}
                                deleteItemFromReturns={deleteItemFromReturns}
                                addItemToReturns={addItemToReturns}
                              />
                            </>
                          )}
                        </IonCol>
                      ))}
                    </IonRow>
                  </div>

                  <OrderSummary
                    order={order}
                    summaryType="new"
                    isDraft={isDraft}
                    discount={order.store?.discount}
                  />

                  <IonRow>
                    <IonCol size="12">
                      <IonItem className="input-container">
                        <IonLabel position="floating">Order Code</IonLabel>
                        <IonInput
                          tabIndex={-1}
                          type="text"
                          value={order.abbreviation}
                          onIonChange={setOrderAbbreviation}
                          placeholder="two letter for order"
                          className="custom-input"
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Recieved Cash</IonLabel>
                        <IonInput
                          tabIndex={-1}
                          type="number"
                          value={order.received_payment}
                          onIonChange={setOrderRecievedPayment}
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Message:</IonLabel>
                        <IonTextarea
                          tabIndex={-1}
                          value={order.message}
                          onIonChange={setOrderMessage}
                        ></IonTextarea>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12">
                      <IonRow>
                        <IonCol
                          size={
                            !isEdit || order.status == ORDER_STATUS.draft
                              ? "6"
                              : "9"
                          }
                        >
                          <IonButton
                            tabIndex={-1}
                            color="primary"
                            expand="full"
                            onClick={() => showSaveConfirmationOrder("save")}
                          >
                            Save
                          </IonButton>
                        </IonCol>
                        {(!isEdit || order.status == ORDER_STATUS.draft) && (
                          <IonCol
                            size={
                              [
                                UserTypes.admin,
                                UserTypes.manager,
                                UserTypes.saleRepresentative,
                              ].includes(userType)
                                ? "3"
                                : "6"
                            }
                          >
                            <IonButton
                              tabIndex={-1}
                              color="success"
                              expand="full"
                              onClick={() => showSaveConfirmationOrder("draft")}
                            >
                              Draft
                            </IonButton>
                          </IonCol>
                        )}
                        {[
                          UserTypes.admin,
                          UserTypes.manager,
                          UserTypes.saleRepresentative,
                        ].includes(userType) && (
                          <IonCol size="3">
                            <IonButton
                              tabIndex={-1}
                              color="danger"
                              expand="full"
                              onClick={() =>
                                document
                                  .getElementById("upload-attachment")
                                  ?.click()
                              }
                            >
                              Attach
                            </IonButton>
                          </IonCol>
                        )}
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )
            }

            {
              /* last 6 orders for store */
              _.map(storeLastOrders, (o, index) => (
                <div key={o.id}>
                  {currentSlide === index + 2 && (
                    <OrderForm
                      productList={categorizedProductList}
                      order={o}
                      looses={looses}
                    />
                  )}
                </div>
              ))
            }
          </>
        )}

        <OrderProductModal
          open={selectedProduct.modalVisiblility}
          productId={selectedProduct.id}
          orderSizes={order.sizes}
          store={order.store!}
          changeOrderSize={changeOrderSize}
          setOrderSize={setOrderSize}
          onDidDismiss={() => {
            setSelectedProduct({ id: "", modalVisiblility: false });
          }}
        />

        <OrderStoreSelectModal
          open={storeSelectModalVisible}
          selectStore={setOrderStore}
          hideModal={() => setStoreSelectModalVisible(false)}
          store={order.store}
        />

        {displayAttachment && (
          <AttachmentModal
            isOpen={displayAttachment}
            toggleModal={toggleAttachmentModal}
            src={tempImage ?? order.attachment}
          />
        )}

        {/* loading bar */}
        <IonLoading
          isOpen={loading || slideChanging}
          message={"Please wait..."}
        />

        <IonAlert
          isOpen={saveOrderConfirmation.visible}
          onDidDismiss={() =>
            setSaveOrderConfirmation((prevState) => ({
              ...prevState,
              visible: false,
            }))
          }
          header={"Save"}
          message={"Do you want to save the order?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Save",
              handler() {
                saveOrderOrSaveAsDraft();
              },
            },
          ]}
        />

        <IonAlert
          isOpen={dontShowStoreMessageAgainConfirm}
          onDidDismiss={() => setDontShowStoreMessageAgainConfirm(false)}
          header={"Do you want to see this message next time?"}
          message={
            "If you choose no, you will not see this message next time you take an order from this store."
          }
          buttons={[
            {
              text: "Yes",
              role: "cancel",
            },
            {
              text: "No",
              handler() {
                acceptDontShowAgainStoreMessage();
              },
            },
          ]}
        />

        {/* Hidden Input Filed */}
        <input
          type="file"
          hidden
          onChange={uploadOrderImg}
          id="upload-attachment"
        />
      </>
    </Layout>
  );
};
