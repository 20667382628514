// @ts-nocheck
import {
  IonCol,
  IonGrid,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import { Avatar } from "../../Avatar/Avatar";
import { ColumnListItemConfig } from "../List";
import "./ListItem.scss";

const ListItem = ({
  columnList,
  listActions,
  item,
  key,
  isMobile = false,
  onRowClick,
}: {
  columnList?: Array<ColumnListItemConfig>;
  listActions: any;
  item: any;
  key: string;
  isMobile?: boolean;
  onRowClick?: (id: number) => void;
}) => {
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const ColItem = (col: ColumnListItemConfig) => {
    let value = "-";
    if (!col.coloumnName.includes("."))
      value = item[col.coloumnName] ? item[col.coloumnName] : "-";
    else {
      const cols = col.coloumnName.split(".");
      value = item[cols[0]][cols[1]] ? item[cols[0]][cols[1]] : "-";
    }
    const types = {
      avatar: <Avatar label="U" img={value} className="avatar" />,
      string: <span>{value}</span>,
      date: <span>{new Date(value).toLocaleDateString()}</span>,
    };

    return types[col.type];
  };

  return (
    <IonItemSliding key={item.id}>
      <IonItem
        key={key}
        className="item-row"
        onClick={() => onRowClick(item.id)}
      >
        <IonGrid>
          {!isMobile ? (
            <IonRow className={"ion-rows"}>
              <IonCol>
                <Avatar
                  label="U"
                  img={item[columnList[0].coloumnName]}
                  className="avatar"
                  color={item.colour ?? ""}
                />
              </IonCol>
              {columnList?.slice(1)?.map((col: ColumnListItemConfig) => (
                <IonCol className="ion-cols">{ColItem(col)}</IonCol>
              ))}
            </IonRow>
          ) : (
            <IonRow className={"ion-rows"}>
              <Avatar
                label="U"
                img={item[columnList[0].coloumnName]}
                className="avatar"
                color={item.colour ?? ""}
              />
              <IonCol
                className="ion-cols"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {columnList
                  ?.slice(1)
                  ?.map((col: ColumnListItemConfig) => ColItem(col))}
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonItem>
      {listActions.map((itemOptions) => (
        <IonItemOptions side={itemOptions.side}>
          {itemOptions.options.map((opt) => (
            <IonItemOption color={opt.color} onClick={() => opt.actionFn(item)}>
              {opt.title}
            </IonItemOption>
          ))}
        </IonItemOptions>
      ))}
    </IonItemSliding>
  );
};

export default ListItem;

{
  /* {listActions?.length > 0 && (
            <IonCol style={{ height: "40px" }} className="pop-over">
              <IonPopover
                isOpen={showPopover.open}
                event={showPopover.event}
                onDidDismiss={() =>
                  setShowPopover({ open: false, event: undefined })
                }
                showBackdrop={false}
              >
                <IonContent className="ion-content">
                  <IonCol className="popover-actions-content">
                    {listActions.map((action: any) => {
                      <IonRow>
                        <IonButton
                          onClick={(e) => action.actionFn(e, action.actionType)}
                        >
                          Delete
                        </IonButton>
                      </IonRow>;
                    })}
                  </IonCol>
                </IonContent>
              </IonPopover>
              <IonToolbar
                className="ion-more-toolbar"
                onClick={(e) =>
                  setShowPopover({ open: true, event: e.nativeEvent })
                }
              >
                <IonRow>
                  <IonIcon
                    className="ion-circle-icon"
                    icon={ellipsisVerticalOutline}
                  ></IonIcon>
                </IonRow>
              </IonToolbar>
            </IonCol>
          )} */
}
